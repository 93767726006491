<template>
  <b-sidebar
    id="sidebar-add-new-profile"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @hidden="cleanProfilesSidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Profiles
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <!-- Customer Name -->
        <b-form-group
          label="First Name"
          label-for="First-name"
        >
          <b-form-input
            v-model="FirstName"
            trim
            placeholder="First Name"
          />
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-for="Last-name"
        >
          <b-form-input
            v-model="LastName"
            trim
            placeholder="Last Name"
          />
        </b-form-group>

        <!-- Email -->
        <b-form-group
          label="Email"
          label-for="Email"
        >
          <b-form-input
            v-model="EmailText"
            trim
            placeholder="Email@gmail.com"
          />
        </b-form-group>

        <!-- Password -->
        <b-form-group
          label="Password"
          label-for="Password"
        >
          <b-form-input
            v-model="Passwordtext"
            trim
            placeholder="°°°°°"
          />
        </b-form-group>

        <!-- EmployeeNumber -->
        <b-form-group
          label="Employee Number"
          label-for="Employee Number"
        >
          <b-form-input
            v-model="EmployeeNumber"
            trim
            placeholder="°°°°°"
          />
        </b-form-group>

        <b-form-group
          label="Select Staff"
          label-for="Select Staff"
        >
          <v-select
            v-model="StaffSelected"
            :options="OptionsStaff"
            label="Name"
            input-id="payment-method"
            class="invoice-filter-select"
            :clearable="true"
          />
        </b-form-group>

        <b-form-group
          label="Roles"
          label-for="Roles"
        >
          <v-select
            v-model="RoleSelected"
            label="text"
            :options="Roles"
          />
        </b-form-group>

        <b-form-group
          v-if="hidesupervisor"
          label="Select your supervisor in charge"
          label-for="Roles"
        >
          <v-select
            v-model="SupervisorSelected"
            label="Name"
            :options="Supervisor"
            :clearable="true"
          />
        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="SaveProfile()"
          >
            Save
          </b-button>
          <b-button
            ref="closeSideBarProfiles"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import profileService from '@core/services/profiles/profileService'
import staffService from '@core/services/staff/staffServices'
import authService from '@core/services/auth/authService'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    getData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Roles: [
        { value: 1, text: 'Admin' },
        { value: 3, text: 'Agent' },
        { value: 2, text: 'Analyst' },
        { value: 4, text: 'Supervisor' },
      ],
      Supervisor: [],
      FirstName: '',
      LastName: '',
      EmailText: '',
      Passwordtext: '',
      StaffSelected: null,
      OptionsStaff: [],
      RoleSelected: null,
      SupervisorSelected: null,
      EmployeeNumber: '',
      idUser: 0,
      hidesupervisor: false,
    }
  },
  watch: {
    getData(val) {
      if (val !== null) {
        this.FirstName = val.FirstName
        this.LastName = val.LastName
        this.EmailText = val.EmailUser
        this.Passwordtext = ''
        this.EmployeeNumber = val.EmployeeNumber
        this.StaffSelected = val.Staff
        this.RoleSelected = this.Roles.find(element => element.value === val.Profile.Id)
        if (val.Supervisor.Id === null) {
          this.SupervisorSelected = null
        } else {
          this.SupervisorSelected = this.Supervisor.find(element => element.Id === val.Supervisor.Id)
        }
        this.idUser = val.IdUser
      } else {
        this.FirstName = ''
        this.LastName = ''
        this.EmailText = ''
        this.Passwordtext = ''
        this.EmployeeNumber = ''
        this.StaffSelected = null
        this.RoleSelected = null
        this.hidesupervisor = false
        this.SupervisorSelected = null
      }
    },
    RoleSelected(val) {
      if (val === null) {
        this.hidesupervisor = false
      } else if (val.value !== 3) {
        this.hidesupervisor = false
      } else {
        this.hidesupervisor = true
      }
    },
  },
  mounted() {
    this.getAllStaffs()
    this.getAllSupervisor()
  },
  methods: {
    getAllStaffs() {
      staffService.getAllStaff().then(response => {
        this.OptionsStaff = response.data.data
      })
    },
    getAllSupervisor() {
      profileService.getAllSupervisor().then(response => {
        this.Supervisor = response.data.data
      })
    },
    cleanProfilesSidebar() {
      this.FirstName = ''
      this.LastName = ''
      this.EmailText = ''
      this.Passwordtext = ''
      this.EmployeeNumber = ''
      this.StaffSelected = null
      this.RoleSelected = null
      this.hidesupervisor = false
      this.SupervisorSelected = null
    },
    SaveProfile() {
      let Text = ''
      if (this.FirstName === '') {
        Text += '- FirstName missing<br>'
      } else {
        Text += ''
      }
      if (this.LastName === '') {
        Text += '- LastName missing<br>'
      } else {
        Text += ''
      }
      if (this.EmailText === '') {
        Text += '- Email missing<br>'
      } else {
        Text += ''
      }
      if (this.getData === null) {
        if (this.Passwordtext === '') {
          Text += '- Password missing<br>'
        } else {
          Text += ''
        }
      }
      if (this.EmployeeNumber === '') {
        Text += '- Employee Number missing<br>'
      } else {
        Text += ''
      }
      if (this.StaffSelected === null || this.StaffSelected <= 0) {
        Text += '- Staff not selected<br>'
      } else {
        Text += ''
      }
      if (this.RoleSelected === null || this.RoleSelected <= 0) {
        Text += '- Role not selected<br>'
        this.SupervisorSelected = null
      } else {
        Text += ''
        if (this.RoleSelected.value === 3) {
          if (this.SupervisorSelected === null || this.SupervisorSelected <= 0) {
            Text += '- Supervisor Manager not selected <br>'
          } else {
            Text += ''
          }
        }
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
      } else if (this.getData === null) {
        Swal.fire({
          title: 'Attention',
          text: ' ¿Do you want to create this profile?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create',
        }).then(result => {
          if (result.isConfirmed) {
            authService.signUp({
              idProfile: this.RoleSelected.value,
              idStaff: this.StaffSelected.Id,
              idSupervisor: this.SupervisorSelected === null ? 0 : this.SupervisorSelected.Id,
              firstName: this.FirstName,
              lastName: this.LastName,
              email: this.EmailText,
              employeeNumber: this.EmployeeNumber,
              password: this.Passwordtext,
            }).then(() => {
              this.refreshData()
              this.$refs.closeSideBarProfiles.click()
              this.getAllSupervisor()
            }).catch(error => {
              this.toastErrorHandler(error)
            })
          }
        })
      } else {
        Swal.fire({
          title: 'Attention',
          text: ' ¿Do you want to Edit this profile?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Edit',
        }).then(result => {
          if (result.isConfirmed) {
            authService.updateUser({
              idUser: this.idUser,
              idProfile: this.RoleSelected.value,
              idStaff: this.StaffSelected.Id,
              idSupervisor: this.SupervisorSelected === null ? 0 : this.SupervisorSelected.Id,
              firstName: this.FirstName,
              lastName: this.LastName,
              email: this.EmailText,
              employeeNumber: this.EmployeeNumber,
              password: this.Passwordtext,
            }).then(() => {
              this.refreshData()
              this.$refs.closeSideBarProfiles.click()
              this.getAllSupervisor()
            }).catch(error => {
              this.toastErrorHandler(error)
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
