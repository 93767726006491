import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class StaffService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getAllStaff(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAllStaff, ...args)
    }

    createStaff(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createStaff, ...args)
    }
}

function useStaffService() {
  const staffService = new StaffService()

  return {
    staffService,
  }
}

const { staffService } = useStaffService()
export default staffService
