<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="PageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <v-select
              v-model="statusApp"
              :options="statusAppList"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select Status"
              :clearable="true"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group style="height: 38px;">
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block"
                  placeholder="Search..."
                  @input="handleSearch"
                />
                <b-input-group-append style="height: 37px;">
                  <b-button
                    v-b-toggle.sidebar-add-new-candidate
                    variant="primary"
                    @click="Create"
                  >
                    Add Candidate
                  </b-button>
                </b-input-group-append>
                <b-input-group-append style="height: 37px;">
                  <b-button
                    variant="success"
                    @click="ExportDataExcel()"
                  >
                    Export Excel
                  </b-button>
                </b-input-group-append>

              </b-input-group>

            <!-- <v-select
              v-model="TypeStaffValue"
              :options="TypeofStaff"
              label="Name"
              input-id="payment-method"
              class="invoice-filter-select"
              placeholder="Select staff"
              :clearable="true"
            /> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :items="items"
        :fields="fields"
        overflow="auto"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        responsive
        style="position: relative !important;"
        @sort-changed="onSortChange"
      >
        <!-- Column: AccountNumber -->
        <template #cell(EmployeeNumber)="">
          <b-link
            class="font-weight-bold"
          >
            #
            <!-- {{ data.value }} -->
          </b-link>
        </template>

        <!-- Column: Patient -->
        <template #cell(Name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                button
                class="border BAvatarPatientProfiles"
                :style="{ color: '#262262 !important', 'background-color' : hexToRgbA(data.item.AppStatusColor) + '!important', }"
                size="32"
                :text="avatarText(`${data.value}`)"
                @click="navigateToProfileInfo(data.item)"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ `${data.value}` }}
            </span>
            <small class="text-muted">
              k
            </small>
          </b-media>
        </template>

        <!-- Column: PhoneNumber -->
        <template #cell(Email)="data">
          <span class="text-nowrap">
            <feather-icon
              icon="MailIcon"
              class="mr-50"
              style="color: #FF9595;"
              @click="openMailto(data.value, data.item.Name)"
            />
            {{ data.value }}
          </span>
        </template>

        <template #cell(Phone)="data">
          <span class="text-nowrap">
            <feather-icon
              icon="PhoneCallIcon"
              class="mr-50"
              style="color: #9AFF95;"
            />

            {{ data.value }}
          </span>
        </template>

        <template
          #cell(AppStatusName)="data"
        >
          <b-badge
            id=""
            pill
            button
            :style="{ color: data.item.AppStatusColor, 'background-color' : hexToRgbA(data.item.AppStatusColor), }"
            class="text-capitalize"
            @click="OpenModalChangeStatus(data.item.IdUser)"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <template
          #cell(DateToContact)="data"
        >
          <span class="text-nowrap">
            <feather-icon
              icon="CalendarIcon"
              class="mr-50"
              style="color: #FF9595;"
            />

            {{ data.value }}
          </span>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :current-page="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <sidebar-add-new-candidate
      :get-data-gender="DataGender"
      :refresh-data="initView"
    />
    <sidebar-add-new-profile
      :get-data="getDataUser"
      :refresh-data="initView"
    />
    <change-status-candidate-modal
      :get-data-user="getDataofUser"
      :refresh-data="initView"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BInputGroup, BInputGroupAppend, BButton, BPagination, BTable, BLink, BMedia, BAvatar, VBToggle, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import profileService from '@core/services/profiles/profileService'
import authService from '@core/services/auth/authService'
import Swal from 'sweetalert2'
import catalogsServices from '@core/services/catalogs/catalogsService'
import SidebarAddNewCandidate from './sidebar/SidebarAddNewCandidate.vue'
import SidebarAddNewProfile from '../Profiles/sidebar/SidebarAddNewProfile.vue'
import ChangeStatusCandidateModal from './modal/ChangeStatusCandidateModal.vue'
import messageEmail from './data/message.json'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BPagination,
    vSelect,
    BTable,
    BLink,
    BMedia,
    BAvatar,
    BBadge,
    SidebarAddNewCandidate,
    SidebarAddNewProfile,
    ChangeStatusCandidateModal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      fields: [
        { key: 'EmployeeNumber', label: '#', sortable: true },
        { key: 'Name', label: 'Candidate', sortable: true },
        { key: 'Email', sortable: true },
        { key: 'Phone', sortable: true },
        { key: 'AppStatusName', label: 'Status', sortable: true },
        { key: 'DateToContact', label: 'Date To Contact', sortable: true }],
      items: [],
      statusAppList: [],
      statusApp: [],
      type: '',
      perPage: 10,
      PageOptions: [10, 25, 50, 100],
      actualpage: 1,
      totalRows: 0,
      currentPage: 1,
      FullCountEntries: 0,
      sortBy: 'Name',
      sortDesc: false,
      sortDirection: 'asc',
      searchTerm: '',
      avatarText,
      getDataUser: {},
      permission: true,
      DataGender: [],
    }
  },
  watch: {
    perPage() {
      this.getProfiles()
    },
    statusApp(val) {
      if (val !== null) {
        this.type = val.Id
      } else {
        this.type = ''
      }
      this.getProfiles()
    },
  },
  mounted() {
    this.getAllStatusApp()
    this.getSpecificCatalog()
  },
  created() {
    this.initView()
  },
  methods: {
    initView() {
      this.getProfiles()
    },
    ExportDataExcel() {
      profileService.exportCandidateTracking({
        name: this.searchTerm,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
        idStaff: '',
        idProfile: '5',
        statusCandidate: this.type,
      }).then(response => {
        const objectURL = URL.createObjectURL(response.data)
        const exportLinkElement = document.createElement('a')
        exportLinkElement.hidden = true
        exportLinkElement.download = 'Candidates Data.xlsx'
        exportLinkElement.href = objectURL
        exportLinkElement.text = 'downloading...'
        document.body.appendChild(exportLinkElement)
        exportLinkElement.click()
        URL.revokeObjectURL(objectURL)
        exportLinkElement.remove()
      })
    },
    openMailto(email, name) {
      const message = messageEmail.text
      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=Documentos para contratación&cc=&bcc=&body=Hola ${name}${message}`)
    },
    getDataofUser(id) {
      authService.getUserById({
        id,
      }).then(response => {
        this.getDataUser = response.data.user
        this.$root.$emit('bv::toggle::collapse', 'sidebar-add-new-profile')
      })
    },
    hexToRgbA(hex) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return `rgba(${r}, ${g}, ${b}, ${0.1})`
    },
    OpenModalChangeStatus(id) {
      this.$bus.$emit('setParamsChangeStatus', { idCandidate: id })
      this.$root.$emit('bv::show::modal', 'modal-change-status-candidate')
    },
    Create() {
      this.getDataUser = null
    },
    DeleteUser(id) {
      Swal.fire({
        title: 'Attention',
        text: ' ¿Do you want to delete this profile?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete',
      }).then(result => {
        if (result.isConfirmed) {
          authService.deleteUser({
            id,
          }).then(() => {
            this.getProfiles()
          }).catch(error => {
            this.toastErrorHandler(error)
          })
        }
      })
    },
    navigateToProfileInfo({ IdUser }) {
      this.$store.commit('profiles/setIdCandidate', IdUser)
      this.$router.push({ name: 'candidate-profile' })
    },
    getAllStatusApp() {
      this.$store.commit('profiles/setIdProfile', null)
      const profile = this.$store.getters['user-auth/getStaffProfile']
      if (profile === 2) {
        this.fields.pop()
        this.permission = false
      }
      catalogsServices.getAllElementCatalog({ table: 'applicationStatus' })
        .then(response => {
          const { data } = response.data
          const filteredList = data.filter(value => value.Id !== 3)
          this.statusAppList = filteredList
        })
    },
    getProfiles() {
      profileService.getProfiles({
        page: this.actualpage,
        name: this.searchTerm,
        rows: this.perPage,
        orderBy: this.sortBy,
        sortBy: this.sortDirection,
        idStaff: '',
        idProfile: '5',
        statusCandidate: this.type,
      }).then(response => {
        this.items = response.data.users
        this.FullCountEntries = response.data.users[0].full_count
        this.numPages = response.data.users[0].pages
        this.totalRows = this.FullCountEntries
        this.currentPage = this.actualpage
      })
    },
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getProfiles()
    },
    handleChangePage(page) {
      this.actualpage = page
      this.getProfiles()
    },
    onSortChange(params) {
      this.sortBy = params.sortBy
      this.sortDesc = params.sortDesc
      const direction = params.sortDesc === true ? 'desc' : 'asc'
      this.sortDirection = direction
      this.getProfiles()
    },
    /* eslint-disable */
    getSpecificCatalog() {
      catalogsServices.getAllElementCatalog({
        table: 'gender',
      }).then(response => {
        const data = response.data.data
        this.DataGender = data
      })
    },
  },
}
</script>

<style>
.per-page-selector {
  width: 90px;
}
.vs__selected-options{
    height: 30px !important;
}

.vs__open-indicator{
  display: none;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 185px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.b-avatar .b-avatar-text{
    font-size: 10px !important;
}

</style>

<style scoped>
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
