<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-change-status-candidate"
    v-b-modal.modal-center
    centered
    title="Status Candidate"
    :hide-footer="true"
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <div>Select new status for the candidate</div>
      <hr class="">
      <b-badge
        v-for="(status, index) in listStatusCandidate"
        id=""
        :key="index"
        pill
        button
        :style="{ color: status.Color + '!important', 'background-color' : hexToRgbA(status.Color), }"
        class="text-capitalize mr-2 mb-1 mt-1"
        @click.prevent="ChangeStatus(status.Id, status.Name, idCandidate)"
      >
        {{ status.Name }}
      </b-badge>
      <!-- <b-dropdown
        id="dropdown-dropup"
        variant="link"
        no-caret
        right
        class="dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >

        <b-dropdown-item
          v-for="(status, index) in listStatusCandidate"
          :key="index"
          @click.prevent="ChangeStatus(status.Id, status.Name, data.item.IdUser)"
        >
          <feather-icon
            icon="DownloadIcon"
            :style="{ color: status.Color + '!important'}"
          />
          <span
            class="align-middle ml-50"
            :style="{ color: status.Color + '!important'}"
          >{{ status.Name }}</span>
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BAvatar, BBadge,
} from 'bootstrap-vue'
import catalogsServices from '@core/services/catalogs/catalogsService'
import usersService from '@core/services/users/usersService'
import Swal from 'sweetalert2'

export default {
  components: {
    BModal,
    BCard,
    BAvatar,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    getDataUser: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      idCandidate: 0,
      listStatusCandidate: [],
    }
  },
  created() {
    this.$bus.$on('setParamsChangeStatus', param => {
      const { idCandidate } = param
      this.idCandidate = idCandidate
    })
    this.getCatalogStatusCandidate()
  },
  methods: {
    getCatalogStatusCandidate() {
      catalogsServices.getAllElementCatalog({ table: 'applicationStatus' })
        .then(response => {
          const { data } = response.data
          this.listStatusCandidate = data
        })
    },
    ChangeStatus(Id, Status, IdUser) {
      this.$root.$emit('bv::hide::modal', 'modal-change-status-candidate')
      Swal.fire({
        title: 'Attention',
        text: ` ¿Do you want to Change the Status to: ${Status}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          usersService.changeStatusCandidate({
            idUser: IdUser,
            idStatus: Id,
          }).then(() => {
            if (Id === 3) {
              this.getDataUser(IdUser)
            } else {
              this.refreshData()
              this.getCatalogStatusCandidate()
            }
          })
        }
      })
    },
    hexToRgbA(hex) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return `rgba(${r}, ${g}, ${b}, ${0.1})`
    },
  },
}
</script>
