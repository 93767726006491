import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class UsersService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    changeStatusCandidate(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.updateStatusCandidate, ...args)
    }

    getUrlFile(url) {
      return `${this.jwtConfig.baseUrlFiles}${url}`
    }
}

function useUsersService() {
  const usersService = new UsersService()

  return {
    usersService,
  }
}

const { usersService } = useUsersService()
export default usersService
