<template>
  <b-sidebar
    id="sidebar-add-new-candidate"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="cleanCandidateSidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Candidate
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <b-form-group
          label="FirstName"
          label-for="FirstName"
        >
          <b-form-input
            v-model="FirstName"
            placeholder="FirstName"
            name="FirstName"
          />
        </b-form-group>

        <b-form-group
          label="LastName"
          label-for="LastName"
        >
          <b-form-input
            v-model="LastName"
            name="LastName"
            placeholder="LastName"
          />
        </b-form-group>

        <b-form-group
          label="E-mail"
          label-for="account-e-mail"
        >
          <b-form-input
            v-model="Email"
            name="email"
            placeholder="Email"
          />

        </b-form-group>

        <b-form-group
          label="Phone"
          label-for="Phone"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon
                icon="PhoneCallIcon"
                size="12"
              />
            </b-input-group-prepend>
            <cleave
              v-model="Phone"
              class="form-control"
              :raw="false"
              :options="options.phone"
              placeholder="1234 567 8900"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="DOB"
          label-for="DOB"
        >
          <flat-pickr
            v-model="DateDob"
            name="DOB"
            placeholder="DOB"
            class="form-control"
          />
        </b-form-group>

        <b-form-group
          label="Gender"
          label-for="Gender"
        >
          <v-select
            v-model="TypeGenderSelected"
            :options="getDataGender"
            label="Name"
            input-id="payment-method"
            class="invoice-filter-select"
            placeholder="Select Gender"
            :clearable="true"
          />
        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="SaveCandidate()"
          >
            Save
          </b-button>
          <b-button
            ref="closeSideBarCandidate"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import authService from '@core/services/auth/authService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    vSelect,
    Cleave,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    getDataGender: {
      type: Array,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      DateDob: null,
      TypeofGender: [],
      TypeGenderSelected: null,
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    cleanCandidateSidebar() {
      this.FirstName = ''
      this.LastName = ''
      this.Email = ''
      this.Phone = ''
      this.DateDob = null
      this.TypeGenderSelected = null
    },
    SaveCandidate() {
      let Text = ''
      if (this.FirstName === '') {
        Text += '- FirstName missing<br>'
      } else {
        Text += ''
      }
      if (this.LastName === '') {
        Text += '- LastName missing<br>'
      } else {
        Text += ''
      }
      if (this.Email === '') {
        Text += '- Email missing<br>'
      } else {
        Text += ''
      }
      if (this.Phone === '') {
        Text += '- Phone Number missing<br>'
      } else {
        Text += ''
      }
      if (this.DateDob === '') {
        Text += '- Date Dob missing<br>'
      } else {
        Text += ''
      }
      if (this.TypeGenderSelected === null) {
        Text += '- Gender not selected<br>'
      } else {
        Text += ''
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
      } else {
        Swal.fire({
          title: 'Attention',
          text: ' ¿Do you want to create this candidate?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create',
        }).then(result => {
          if (result.isConfirmed) {
            authService.createCandidate({
              firstName: this.FirstName,
              lastName: this.LastName,
              email: this.Email,
              phone: this.Phone,
              dob: this.DateDob,
              gender: this.TypeGenderSelected.Id,
            }).then(() => {
              this.refreshData()
              Swal.fire(
                'Success',
                'Candidate Save!',
                'success',
              )
              this.cleanCandidateSidebar()
              this.$refs.closeSideBarCandidate.click()
            }).catch(error => {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '',
                    text: error.response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Network Error',
                    text: 'Your network connection is not available',
                    icon: 'WifiOffIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
