import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ProfileService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getProfiles(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getProfiles, ...args)
    }

    getSimpleUserInfo(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getSimpleUserInfo, ...args)
    }

    getAdvancedUserInfo(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAdvancedUserInfo, ...args)
    }

    createProfile(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createProfile, ...args)
    }

    getAllSupervisor(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAllSupervisor, ...args)
    }

    getHistoryOfSupervisorRecord(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getHistoryOfSupervisorRecord, ...args)
    }

    exportCandidateTracking(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.exportCandidateTracking, ...args, { responseType: 'blob' })
    }
}

function useProfileService() {
  const profileService = new ProfileService()

  return {
    profileService,
  }
}

const { profileService } = useProfileService()
export default profileService
